import React, { useState, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { Menu, Modal, TextField, Button } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";

import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext";
import ForwardModal from "../../components/ForwardMessageModal";

import toastError from "../../errors/toastError";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);

	const {
		showSelectMessageCheckbox,
		setShowSelectMessageCheckbox,
		selectedMessages,
		forwardMessageModalOpen,
		setForwardMessageModalOpen
	} = useContext(ForwardMessageContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	// Novo estado para o modal e o corpo da mensagem editada
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [editedMessageBody, setEditedMessageBody] = useState(message.body);

	const handleEditMessage = () => {
		setEditedMessageBody(message.body);
		setEditModalOpen(true);
		handleClose();
	};

	const saveEditedMessage = async () => {
		try {
			await api.post(`/messages/edit/${message.id}`, { body: editedMessageBody });
			setEditModalOpen(false);
		} catch (error) {
			toastError(error);
		}
	};

	const handleSetShowSelectCheckbox = () => {
		setShowSelectMessageCheckbox(!showSelectMessageCheckbox);
		handleClose();
	};

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${message.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleReplyMessage = () => {
		setReplyingMessage(message);
		handleClose();
	};

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
		handleClose();
	};

	const isWithinFifteenMinutes = () => {
		const fifteenMinutesInMilliseconds = 15 * 60 * 1000; // 15 minutos em milissegundos
		const currentTime = new Date();
		const messageTime = new Date(message.updatedAt);

		// Verifica se a diferença entre o tempo atual e o tempo da mensagem é menor que 15 minutos
		return currentTime - messageTime <= fifteenMinutesInMilliseconds;
	};

	return (
		<>
			<ForwardModal
				modalOpen={forwardMessageModalOpen}
				messages={selectedMessages}
				onClose={() => {
					setForwardMessageModalOpen(false);
					setShowSelectMessageCheckbox(false);
				}}
			/>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={() => setConfirmationOpen(false)}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				<MenuItem onClick={handleSetShowSelectCheckbox}>
					{i18n.t("messageOptionsMenu.forward")}
				</MenuItem>
				{message.fromMe && isWithinFifteenMinutes() && (
					<MenuItem key="edit" onClick={handleEditMessage}>
						{i18n.t("messageOptionsMenu.edit")}
					</MenuItem>
				)}
				{message.fromMe && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("messageOptionsMenu.delete")}
					</MenuItem>
				)}
				<MenuItem onClick={handleReplyMessage}>
					{i18n.t("messageOptionsMenu.reply")}
				</MenuItem>
			</Menu>

			{/* Modal para editar mensagem */}
			<Modal
				open={editModalOpen}
				onClose={() => setEditModalOpen(false)}
				aria-labelledby="edit-message-modal"
				aria-describedby="modal-to-edit-message"
			>
				<div style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					backgroundColor: 'white',
					padding: '16px',
					borderRadius: '8px',
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
				}}>
					<h2>{i18n.t("messageOptionsMenu.editMessage")}</h2>
					<TextField
						fullWidth
						variant="outlined"
						value={editedMessageBody}
						onChange={(e) => setEditedMessageBody(e.target.value)}
					/>
					<Button
						onClick={saveEditedMessage}
						variant="contained"
						color="primary"
						style={{ marginTop: '16px' }}
					>
						{i18n.t("messageOptionsMenu.save")}
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default MessageOptionsMenu;
