import { useContext } from "react";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useDashboard = () => {
    const { user } = useContext(AuthContext);

    const getFromCache = (key) => {
        const cachedData = sessionStorage.getItem(key);
        if (cachedData) {
            return JSON.parse(cachedData);
        }
        return null;
    };

    const setToCache = (key, data) => {
        sessionStorage.setItem(key, JSON.stringify(data));
    };

    const fetchFromServer = async (key, fetchFunction) => {
        const data = await fetchFunction();
        setToCache(key, data);
        return data;
    };

    const find = async (params) => {
        if(user.super) {
            const { data } = await api.request({
                url: `/superadmin/dashboard`,
                method: 'GET',
                params
            });
            return data
        }
        if (!params?.date_from) return;
        const { data } = await api.request({
            url: `/dashboard`,
            method: 'GET',
            params
        });
        return data;
    }

    const getReport = async (params) => {
        const key = `report-${JSON.stringify(params)}`;
        const cachedData = getFromCache(key);
        if (cachedData) {
            return cachedData; // Retorna do cache
        }

        return await fetchFromServer(key, async () => {
            const { data } = await api.request({
                url: `/ticket/reports`,
                method: "GET",
                params,
            });
            return data;
        });
    };

    const getServiceQuality = async (companyId) => {
        const key = `serviceQuality-${companyId}`;
        const cachedData = getFromCache(key);
        if (cachedData) {
            return cachedData; // Retorna do cache
        }

        return await fetchFromServer(key, async () => {
            const { data } = await api.request({
                url: "/service-quality",
                method: "POST",
                data: { companyId },
            });
            return data;
        });
    };

    return {
        find,
        getReport,
        getServiceQuality,
    };
};

export default useDashboard;
