import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import NavigationTabs from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";
import themeBigLogo from "../../assets/envAssets/bigLogo";

const useStyles = makeStyles(theme => ({
    chatContainer: {
        flex: 1,
        padding: "0.5rem",
        height: `calc(100% - 64px)`,
        overflowX: "hidden"
    },
    chatPapper: {
        display: "flex",
        height: "100%",
        gap: "0.5rem",
        justifyContent: "space-between",
    },
    contactsWrapper: {
        display: "flex",
        height: "100%",
        gap: "0.7rem",
        flexDirection: "column",
        whiteSpace: "100%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    messagesWrapper: {
        display: "flex",
        height: "100%",
        overflowX: (props) => (props.drawerOpen ? "visible" : "hidden"),
        flexDirection: "column",
        width: "100%",
        transition: "none",

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

        "&::-webkit-scrollbar": {
            width: "0px",
            height: "0px",
        },
        scrollbarWidth: "none",
    },

    welcomeMsg: {
        backgroundColor: theme.palette.boxticket,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        borderRadius: "8px",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
        "@media (max-width: 600px)": {
            display: "none",
        }
    },
}));

const TicketsCustom = () => {
    const { ticketId } = useParams(); // Obtém o ticketId da URL

    const [drawerOpen, setDrawerOpen] = useState(() => {
        const savedDrawerOpen = localStorage.getItem("drawerOpen");
        return savedDrawerOpen !== null ? JSON.parse(savedDrawerOpen) : false;
    });

    const [mobileUserOpen, setMobileUserOpen] = useState(false);
    const [hideMessages, setHideMessages] = useState(false);
    const [mobileAdditionalInfoOpen, setMobileAdditionalInfoOpen] = useState(false);
    const classes = useStyles({ drawerOpen });

    useEffect(() => {
        localStorage.setItem("drawerOpen", JSON.stringify(drawerOpen));
    }, [drawerOpen]);

    useEffect(() => {
        if (!ticketId) {
            setMobileUserOpen(false);
            setHideMessages(false);
            setMobileAdditionalInfoOpen(false);
        } else {
            setMobileUserOpen(true);
            return;
        }

        return () => {
            setMobileUserOpen(false);
            setHideMessages(false);
            setMobileAdditionalInfoOpen(false);
        };
    }, [ticketId]);

    return (
        <div className={`
        ${classes.chatContainer} 
        ${mobileUserOpen ? "cmp-tickets__mobile-ticket-container--open" : ""} 
        ${mobileAdditionalInfoOpen ? "cmp-tickets__additional-info--open" : ""} 
        ${hideMessages ? "cmp-tickets__accept-ticket--open" : ""}
        `}>
            <div className={classes.chatPapper}>
                <Grid item className={classes.contactsWrapper}>
                    <NavigationTabs setHideMessages={setHideMessages} setMobileUserOpen={setMobileUserOpen} />
                </Grid>
                <Grid item className={`${classes.messagesWrapper} cmp-messages__wrapper`}>
                    {ticketId ? (
                        <Ticket setMobileAdditionalInfoOpen={setMobileAdditionalInfoOpen} setMobileUserOpen={setMobileUserOpen} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
                    ) : (
                        <Paper square variant="outlined" className={classes.welcomeMsg}>
                            <div>
                                <center><img style={{ margin: "0 auto", width: "70%", maxHeight: "150px" }} src={themeBigLogo[process.env.REACT_APP_THEME_LOGO]} alt="logologin" /></center>
                            </div>
                        </Paper>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default TicketsCustom;
