import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api'; // Ajuste conforme o seu projeto
import { 
  Table, TableBody, TableCell, TableHead, TableRow, 
  Button, TextField, MenuItem, Grid, Paper, Typography, 
  makeStyles,
  useTheme
} from '@material-ui/core';
import { AuthContext } from '../../context/Auth/AuthContext'; // Pega o usuário logado do contexto
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// Interface para as chamadas
interface Call {
  id: number;
  callId: string;
  wppId: string;
  userName: string;
  companyId: number;
  createdAt: string;
  contactName: string;
  contactNumber: string;
  ticketId: number;  // Adicionando o campo ticketId
}

// Interface para as empresas
interface SimpleCompany {
  id: number;
  name: string;
}
const useStyles = makeStyles((theme: any) => ({
  paper: 
    {
      "& h6, & p, & label, & div": {
        fontSize: "14px",
        fontFamily: "Poppins"
      },
    },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    border: "2px solid red",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.palette.options,
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.fontecor,
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  boxContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  boxContainerNoFlex: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
}));
const CallsByCompany = () => {
  const { user } = useContext(AuthContext); // Pega o usuário logado do AuthContext
  const [calls, setCalls] = useState<Call[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [availableCompanies, setAvailableCompanies] = useState<SimpleCompany[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(user.super ? '' : user.companyId.toString()); // Filtro de empresa
  const [startDate, setStartDate] = useState<string>(''); // Filtro por data de início
  const [endDate, setEndDate] = useState<string>(''); // Filtro por data de fim
  const [audioUrls, setAudioUrls] = useState<{ [key: string]: string }>({}); // Guarda os URLs dos áudios
  const theme: any = useTheme();

  // Carrega a lista de empresas para o filtro de seleção (somente para superadmin)
  useEffect(() => {
    if (user.super) {
      const fetchCompanies = async () => {
        try {
          const response = await api.get('/api/messages/companies');
          setAvailableCompanies(response.data);
        } catch (err) {
          console.error('Erro ao buscar empresas', err);
        }
      };
      fetchCompanies();
    }
  }, [user.super]);
  const classes: any = useStyles();

  // Função para buscar chamadas filtradas por companyId e data
  const handleFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const params = {
        startDate,
        endDate,
        companyId: selectedCompanyId, // Se for superadmin, seleciona a empresa; caso contrário, já está definido
      };

      const response = await api.get('/api/calls', { params });
      setCalls(response.data);

      // Pré-carregar URLs dos áudios para cada chamada
      const audioUrls: { [key: string]: string } = {};
      for (const call of response.data) {
        const audioResponse = await api.get(`/api/calls/${call.callId}`, {
          params: { companyId: call.companyId.toString() },
          responseType: 'blob',
        });
        audioUrls[call.callId] = window.URL.createObjectURL(new Blob([audioResponse.data]));
      }
      setAudioUrls(audioUrls);
    } catch (err) {
      setError('Erro ao buscar chamadas');
      console.error('Erro na requisição:', err);
    } finally {
      setLoading(false);
    }
  };

  // Função para baixar o áudio de uma chamada
  const handleDownloadAudio = async (callId: string, companyId: string) => {
    try {
      const response = await api.get(`/api/calls/${callId}`, {
        params: { companyId },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${callId}.mp3`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o áudio:', error);
    }
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className={classes.paper}>
      <h6 style={{ marginBottom: "1rem", fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite }}>
        Chamadas por Empresa
      </h6>

      <Paper elevation={3} style={{ padding: '1rem', marginBottom: '2rem', borderRadius: "8px", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }}>
        <form onSubmit={handleFilter}>
          <Grid container spacing={2} alignItems="center">
            {/* Se for superadmin, exibe o campo para selecionar empresa */}
            {user.super && (
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Empresa"
                  value={selectedCompanyId}
                  onChange={(e) => setSelectedCompanyId(e.target.value)}
                  fullWidth
                >
                  {availableCompanies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {/* Se não for superadmin, exibe apenas a empresa associada ao usuário */}
            {!user.super && (
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Empresa: {user.company.name}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Fim"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button className={classes.btnStyles} type="submit" fullWidth>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {calls.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          Nenhuma chamada encontrada.
        </Typography>
      ) : (
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">ID da Chamada</TableCell>
                <TableCell align="center">Nome do Usuário</TableCell>
                <TableCell align="center">Nome do Destinatário</TableCell>
                <TableCell align="center">Número do Destinatário</TableCell>
                <TableCell align="center">Ticket ID</TableCell> {/* Exibindo o ticketId */}
                <TableCell align="center">Data de Criação</TableCell>
                <TableCell align="center">Áudio</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calls.map((call) => (
                <TableRow key={call.id}>
                  <TableCell align="center">{call.callId}</TableCell>
                  <TableCell align="center">{call.userName}</TableCell>
                  <TableCell align="center">{call?.contactName}</TableCell>
                  <TableCell align="center">{call.contactNumber}</TableCell>
                  <TableCell align="center">{call.ticketId}</TableCell> {/* Exibindo o ticketId */}
                  <TableCell align="center">
                    {format(new Date(call.createdAt), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR })}
                  </TableCell>
                  <TableCell align="center">
                  {audioUrls[call.callId] && (
                    <audio controls style={{ width: '250px', height: '30px' }}>
                      <source src={audioUrls[call.callId]} type="audio/mp3" />
                      Seu navegador não suporta o elemento de áudio.
                    </audio>
                  )}
                </TableCell>

                  <TableCell align="center">
                    <Button
                      className={classes.btn}
                      onClick={() => handleDownloadAudio(call.callId, call.companyId.toString())}
                    >
                      Baixar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export default CallsByCompany;
