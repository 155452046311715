import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    padding: "0.5rem",
    borderRadius: "8px",
    height: `calc(100% - 64px)`,
    overflowY: "hidden",
    backgroundColor: "transparent !important",
  },
  gridContainer: {
    height: "100%",
    flexWrap: "nowrap",
    display: "flex",
    gap: "0.5rem",
    backgroundColor: "transparent",
  },
  gridItem: {
    height: "100%",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F4F4F4",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#F4F4F4",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#dad7d7",
    },
  },
  gridItemTab: {
    height: "92%",
    width: "100%",
  },
  btnContainer: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    color: theme.palette.primary.mainReverseByWhite,
    "& h3": {
      fontFamily: "Poppins",
      fontWeight: "500",
      fontSize: "14px",
      color: theme.palette.primary.mainReverseByWhite
    }
  },
  btnStyles: {
    minWidth: "25px",
    width: "25px",
    height: "25px",
    minHeight: "25px",
    padding: "0",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  }
}));

export default useStyles;