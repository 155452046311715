import React from "react";

import ContactModal from "../../../../components/ContactModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";

import { i18n } from "../../../../translate/i18n";
import NewTicketModal from "../../../../components/NewTicketModal";
import UserSelectModal from "../../../../components/ContactToUser";

import ContactImportWpModal from "../../../../components/ContactImportWpModal";

const Modals = ({
    userSelectModalOpen,
    setImportContactModalOpen,
    selectedContactIdForUser,
    handleCloseUserSelectModal,
    newTicketModalOpen,
    contactTicket,
    handleCloseOrOpenTicket,
    importContactModalOpen,
    hideNum,
    user,
    contactModalOpen,
    handleCloseContactModal,
    selectedContactId,
    confirmOpen,
    setConfirmOpen,
    deletingContact,
    handleDeleteContact,
    handleimportContact,
    selectedContact
}) => {
    return (
        <>
            {userSelectModalOpen && (
                <UserSelectModal
                    contactId={selectedContactIdForUser}
                    onClose={handleCloseUserSelectModal}
                />
            )}
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />

            <ContactImportWpModal
                isOpen={importContactModalOpen}
                handleClose={() => setImportContactModalOpen(false)}
                selectedTags={null}
                hideNum={hideNum}
                userProfile={user.profile}
            />

            <ContactModal
                user={user}
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
                ticket={selectedContact?.tickets?.length ? selectedContact?.tickets[0] : null}
            ></ContactModal>

            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
                        }?`
                        : `${i18n.t("contacts.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={(e) =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleimportContact()
                }
            >
                {deletingContact
                    ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
                    : `${i18n.t("contacts.confirmationModal.importMessage")}`}
            </ConfirmationModal>
        </>
    );
};

export default Modals;
