// ContactDetailsModal.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Divider,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TodayIcon from "@material-ui/icons/Today";
import AssignmentIcon from "@material-ui/icons/Assignment";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ContactDetailsModal = ({ contactId, companyId, open, onClose }) => {
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await api.get(`/contacts/${contactId}/list-item-details`, {
          params: { companyId },
        });
        setContactDetails(response.data);
      } catch (error) {
        toastError(error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      setLoading(true);
      fetchContactDetails();
    }
  }, [contactId, companyId, open]);

  const detailsList = [
    { label: "Nome", value: contactDetails?.name, icon: <PersonIcon /> },
    { label: "Número", value: contactDetails?.number, icon: <PhoneIcon /> },
    { label: "Email", value: contactDetails?.email, icon: <EmailIcon /> },
    { label: "CPF", value: contactDetails?.cpf, icon: <AssignmentIcon /> },
    { label: "Número do Contrato", value: contactDetails?.contractNumber, icon: <AssignmentIcon /> },
    { label: "Valor da Dívida", value: contactDetails?.debtValue, icon: <AttachMoneyIcon /> },
    { label: "Dias em Atraso", value: contactDetails?.overdueDays, icon: <TodayIcon /> },
    { label: "Observação", value: contactDetails?.observation, icon: <DescriptionIcon /> },
    { label: "Campo 1", value: contactDetails?.field1, icon: <DescriptionIcon /> },
    { label: "Campo 2", value: contactDetails?.field2, icon: <DescriptionIcon /> },
    { label: "Campo 3", value: contactDetails?.field3, icon: <DescriptionIcon /> },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth style={{zIndex: "5000", position: "absolute"}}>
      <DialogTitle
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          textAlign: "center",
          padding: "16px",
        }}
      >
        Detalhes do Contato
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: theme.palette.background.default,
          padding: "16px",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : contactDetails ? (
          <Grid container spacing={2}>
            {detailsList.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Box
                    style={{
                      color: theme.palette.primary.mainReverseByWhite,
                      marginRight: "8px",
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" style={{ color: theme.palette.text.primary }}>
                      {item.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.text.secondary,
                        wordBreak: "break-word",
                      }}
                    >
                      {item.value || "Não disponível"}
                    </Typography>
                  </Box>
                </Box>
                {/* Divider to separate items */}
                {index % 2 !== 1 && index < detailsList.length - 1 && (
                  <Divider style={{ margin: "8px 0" }} />
                )}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" style={{ color: theme.palette.text.primary }}>
            Nenhum detalhe encontrado.
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: theme.palette.background.default,
          padding: "8px 16px",
        }}
      >
        <Button
          onClick={onClose}
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          }}
          variant="contained"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDetailsModal;
