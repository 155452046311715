const getSocialBackgroundColor = (type, theme) => {
    switch (type) {
        case "facebook":
            return "#1877f2"; // Azul padrão do Facebook
        case "instagram":
            return "linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%)";
        case "baileys":
            return "#25D366";
        case "oficial":
            return "#25D366"; // Verde padrão do WhatsApp
        default:
            return theme.palette.primary.main; // Cor padrão para 'All' ou qualquer outro
    }
};

export default getSocialBackgroundColor;