import React from "react";
import { Field } from "formik";

import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";

import { i18n } from "../../../../translate/i18n";
import QueueSelect from "../../../QueueSelect";

const QueueAndOtherInputs = ({
    isSocial,
    isCallsEnabled,
    selectedQueueIds,
    handleChangeQueue,
    selectedPrompt,
    handleChangePrompt,
    prompts,
    classes,
    modelType
}) => {
    return (
        <div className={classes.switchLabels}>
            {!(modelType === "facebookOfficial" || modelType === "instagramOfficial") &&<div>
                <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.token")}
                    type="token"
                    fullWidth
                    name="token"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>}
            {(!isSocial && isCallsEnabled && !(modelType === "facebookOfficial" || modelType === "instagramOfficial")) && (
                <div>
                    <Field
                        as={TextField}
                        label="Token chamadas WhatsApp"
                        type="tokenwavoip"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        name="tokenwavoip"
                        variant="outlined"
                        margin="dense"
                    />
                </div>
            )}
            <QueueSelect
                selectedQueueIds={selectedQueueIds}
                onChange={(selectedIds) => handleChangeQueue(selectedIds)}
            />

            {(!isSocial && !(modelType === "facebookOfficial" || modelType === "instagramOfficial")) && <FormControl
                margin="dense"
                variant="outlined"
                fullWidth
            >
                <InputLabel>
                    {i18n.t("whatsappModal.form.prompt")}
                </InputLabel>
                <Select
                    labelId="dialog-select-prompt-label"
                    id="dialog-select-prompt"
                    name="promptId"
                    value={selectedPrompt || ""}
                    onChange={handleChangePrompt}
                    label={i18n.t("whatsappModal.form.prompt")}
                    fullWidth
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {prompts.map((prompt) => (
                        <MenuItem
                            key={prompt.id}
                            value={prompt.id}
                        >
                            {prompt.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>}
        </div>
    );
};

export default React.memo(QueueAndOtherInputs);
