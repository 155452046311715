import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  Chip,
  CircularProgress,
  Paper,
  Box,
  TextField,
  useTheme,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useWhatsApps from '../../hooks/useWhatsApps';
import * as XLSX from 'xlsx';


const ManipulateUsersComponent = () => {
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [usersToPromote, setUsersToPromote] = useState([]);
  const [usersToDemote, setUsersToDemote] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [promoteAdminsList, setPromoteAdminsList] = useState([]);
  const [demoteAdminsList, setDemoteAdminsList] = useState([]);
  const [removeUsersList, setRemoveUsersList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const { whatsApps, loading } = useWhatsApps();
  const [isSaving, setIsSaving] = useState(false);
  const [actions, setActions] = useState({
    promoteAdmins: false,
    demoteAdmins: false,
    addUsers: false,
    removeUsers: false,
    importContacts: true, // Importar contatos inicia como ativado
  });
  const [contactsList, setContactsList] = useState([]); // Estado para armazenar os contatos
  const [isLoadingContacts, setIsLoadingContacts] = useState(false); // Estado de carregamento de contatos

  if(isLoadingContacts) {
    console.log(isLoadingContacts);
  }

  const fetchContacts = async () => {
    const companyId = localStorage.getItem('companyId');
    if (!companyId) {
      toast.error('Company ID não encontrado.');
      return;
    }

    try {
      setIsLoadingContacts(true);
      const { data } = await api.get('/api/groups/contacts', { params: { companyId } });
      const contacts = data.data.map((contact) => ({
        label: contact.name || contact.number,
        value: contact.number,
      }));
      setContactsList(contacts);
    } catch (err) {
      toast.error('Erro ao buscar contatos.');
    } finally {
      setIsLoadingContacts(false);
    }
  };

  useEffect(() => {
    if (selectedConnection) {
      const fetchGroups = async () => {
        try {
          setIsLoadingGroups(true);
          const { data } = await api.get(`/api/groups/list-groups`, {
            params: { whatsappId: selectedConnection },
          });
          setGroups(data.groups);
        } catch (err) {
          toast.error('Erro ao buscar grupos.');
        } finally {
          setIsLoadingGroups(false);
        }
      };
      fetchGroups();
    }
  }, [selectedConnection]);

  const handleImportXLS = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      let workbook;

      if (file.name.endsWith('.csv')) {
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        workbook = XLSX.read(data, { type: 'array' });
      }

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const importedNumbers = jsonData
        .map((row) => row[0])
        .filter(Boolean);

      setUsersToAdd((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToRemove((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToPromote((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToDemote((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
    };

    if (file.name.endsWith('.csv')) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const fetchGroupUsers = useCallback(async (groupId) => {
    try {
      const { data } = await api.post('/api/groups/list-group-participants', {
        whatsappId: selectedConnection,
        groupIds: [groupId],
      });
  
      const users = data.data[0].participants.map((participant) => ({
        label: participant.name || participant.id.replace('@s.whatsapp.net', ''),
        value: participant.id.replace('@s.whatsapp.net', ''),
      }));
  
      setPromoteAdminsList(users);
      setDemoteAdminsList(users);
      setRemoveUsersList(users);
    } catch (err) {
      toast.error('Erro ao buscar usuários do grupo.');
    }
  }, [selectedConnection, setPromoteAdminsList, setDemoteAdminsList, setRemoveUsersList]);
  
  useEffect(() => {
    if (selectedGroup) {
      fetchGroupUsers(selectedGroup);
    }
  }, [selectedGroup, fetchGroupUsers]);
  

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'addUsers') {
      setActions((prevActions) => ({
        ...prevActions,
        addUsers: checked,
        removeUsers: false,
        promoteAdmins: false,
        demoteAdmins: false,
      }));

      if (checked) {
        fetchContacts();
      } else {
        setUsersToAdd([]);
      }

      setUsersToRemove([]);
      setUsersToPromote([]);
      setUsersToDemote([]);
    }
    else if (name === 'removeUsers') {
      setActions((prevActions) => ({
        ...prevActions,
        removeUsers: checked,
        addUsers: false,
        promoteAdmins: false,
        demoteAdmins: false,
      }));

      if (!checked) {
        setUsersToRemove([]);
      }

      setUsersToAdd([]);
      setUsersToPromote([]);
      setUsersToDemote([]);
    }
    else if (name === 'promoteAdmins') {
      setActions((prevActions) => ({
        ...prevActions,
        promoteAdmins: checked,
        addUsers: false,
        removeUsers: false,
        demoteAdmins: false,
      }));

      if (!checked) {
        setUsersToPromote([]);
      }

      setUsersToRemove(checked ? usersToRemove : []);
      setUsersToAdd([]);
      setUsersToRemove([]);
      setUsersToDemote([]);
    }
    else if (name === 'demoteAdmins') {
      setActions((prevActions) => ({
        ...prevActions,
        demoteAdmins: checked,
        addUsers: false,
        removeUsers: false,
        promoteAdmins: false,
      }));

      if (!checked) {
        setUsersToDemote([]);
      }

      setUsersToDemote(checked ? usersToDemote : []);
      setUsersToAdd([]);
      setUsersToRemove([]);
      setUsersToPromote([]);
    }

    else if (name === 'importContacts') {
      setActions((prevActions) => ({
        ...prevActions,
        importContacts: checked,
      }));
    }
  };
  const formatPhoneNumbers = (numbers) => {
    return numbers.map(number => {
      number = number.replace(/\D/g, '');

      if (number.length === 13 && number.startsWith('9', 4)) {
        return number.slice(0, 4) + number.slice(5);
      }

      return number;
    });
  };

  const handleExecute = async () => {
    if (!selectedGroup) {
      toast.error('Selecione um grupo.');
      return;
    }

    setIsSaving(true);
    try {
      const usersToAddArray = formatPhoneNumbers(usersToAdd);
      const usersToRemoveArray = formatPhoneNumbers(usersToRemove);
      const usersToPromoteArray = formatPhoneNumbers(usersToPromote);
      const usersToDemoteArray = formatPhoneNumbers(usersToDemote);

      if (actions.promoteAdmins && usersToPromote.length > 0) {

        await api.post('/api/groups/promote-admins', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToPromoteArray,
        });
      }

      if (actions.demoteAdmins && usersToDemote.length > 0) {

        await api.post('/api/groups/demote-admins', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToDemoteArray,
        });
      }

      if (actions.addUsers && usersToAddArray.length > 0) {

        await api.post('/api/groups/add-users', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToAddArray,
        });
      }

      if (actions.removeUsers && usersToRemoveArray.length > 0) {

        await api.post('/api/groups/remove-users', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToRemoveArray,
        });
      }

      toast.success('Ações aplicadas com sucesso!');
    } catch (error) {
      console.error("Erro ao aplicar as ações:", error);
      toast.error('Erro ao aplicar as ações.');
    } finally {
      setIsSaving(false);
    }
  };


  const handleClear = () => {
    setSelectedConnection('');
    setSelectedGroup('');
    setUsersToPromote([]);
    setUsersToDemote([]);
    setUsersToAdd([]);
    setUsersToRemove([]);
    setActions({
      promoteAdmins: false,
      demoteAdmins: false,
      addUsers: false,
      removeUsers: false,
      importContacts: true,
    });
  };
  const theme = useTheme();
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Manipular Usuários
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3} direction={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={whatsApps.filter((contact) => contact.type === null)}
                  getOptionLabel={(option) => option.name || option.number}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Conexão"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: theme.palette.primary.mainReverseByWhite },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          color: theme.palette.primary.mainReverseByWhite,
                        },
                        classes: {
                          notchedOutline: {
                            borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                          },
                        },
                      }}
                    />
                  )}
                  value={whatsApps.filter((contact) => contact.type === null).find((whatsApp) => whatsApp.id === selectedConnection) || null}
                  onChange={(event, newValue) => setSelectedConnection(newValue ? newValue.id : '')}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={groups}
                  getOptionLabel={(option) => option.subject}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grupos"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: { color: theme.palette.primary.mainReverseByWhite },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderColor: theme.palette.primary.mainReverseByWhite,
                          color: theme.palette.primary.mainReverseByWhite,
                        },
                        classes: {
                          notchedOutline: {
                            borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                          },
                        },
                      }}
                    />
                  )}
                  value={groups.find((group) => group.id === selectedGroup) || null}
                  onChange={(event, newValue) => setSelectedGroup(newValue ? newValue.id : '')}
                  disabled={!selectedConnection || groups.length === 0 || isLoadingGroups}
                />
                {isLoadingGroups && <CircularProgress size={24} />}
              </Grid>
            </Grid>


            <Grid container spacing={3} style={{ marginTop: '20px' }} direction={{ xs: 'column', sm: 'row' }}>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.importContacts}
                      onChange={handleSwitchChange}
                      name="importContacts"
                      color="primary"
                    />
                  }
                  label="Importar Contatos"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.addUsers}
                      onChange={handleSwitchChange}
                      name="addUsers"
                      color="primary"
                    />
                  }
                  label="Adicionar Usuários"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.removeUsers}
                      onChange={handleSwitchChange}
                      name="removeUsers"
                      color="primary"
                    />
                  }
                  label="Remover Usuários"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.promoteAdmins}
                      onChange={handleSwitchChange}
                      name="promoteAdmins"
                      color="primary"
                    />
                  }
                  label="Promover Admins"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.demoteAdmins}
                      onChange={handleSwitchChange}
                      name="demoteAdmins"
                      color="primary"
                    />
                  }
                  label="Remover privilégios de Admin"
                />
              </Grid>
            </Grid>


            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              {actions.addUsers && (
                <Grid item xs={12}>
                  {actions.importContacts ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={contactsList}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecionar Contatos para Adicionar"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: theme.palette.primary.mainReverseByWhite },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              borderColor: theme.palette.primary.mainReverseByWhite,
                              color: theme.palette.primary.mainReverseByWhite,
                            },
                            classes: {
                              notchedOutline: {
                                borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                              },
                            },
                          }}
                        />
                      )}
                      value={contactsList.filter((user) => usersToAdd.includes(user.value))}
                      onChange={(event, newValue) => {
                        setUsersToAdd(newValue.map((user) => user.value));
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const optionValue = typeof option === 'object' ? option.value : option;
                          const user = contactsList.find((user) => user.value === optionValue);
                          return (
                            <Chip
                              style={{
                                backgroundColor: `${theme.palette.primary.main}70`,
                                color: theme.palette.primary.contrastText,
                                border: `2px solid ${theme.palette.primary.main}`,
                                borderRadius: '8px',
                              }}
                              key={optionValue}
                              label={user ? user.label : 'Nome não disponível'}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />

                  ) : (
                    <>
                      <TextField
                        label="Adicionar Usuários (separado por vírgula)"
                        variant="outlined"
                        fullWidth
                        value={usersToAdd.join(',')}
                        onChange={(e) => setUsersToAdd(e.target.value.split(',').map((user) => user.trim()))}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <label htmlFor="import-excel">
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: '10px' }}
                          component="span"
                        >
                          IMPORTAR DE XLS/CSV
                        </Button>
                      </label>


                    </>
                  )}
                </Grid>
              )}

              {actions.removeUsers && (
                <Grid item xs={12}>
                  {actions.importContacts ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={removeUsersList}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{
                          shrink: true,
                          style: { color: theme.palette.primary.mainReverseByWhite },
                        }}
                          InputProps={{
                            style: {
                              color: theme.palette.primary.mainReverseByWhite,
                            },
                            classes: {
                              notchedOutline: {
                                borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                              },
                            },
                          }} label="Selecionar Contatos para Remover" variant="outlined" />
                      )}
                      value={removeUsersList.filter((user) => usersToRemove.includes(user.value))}
                      onChange={(event, newValue) => {
                        setUsersToRemove(newValue.map((user) => user.value));
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const user = removeUsersList.find((user) => user.value === option.value);
                          return (
                            <Chip
                              key={option.value}
                              label={user ? user.label : 'Nome não disponível'}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  ) : (
                    <>
                      <TextField
                        label="Remover Usuários (separado por vírgula)"
                        variant="outlined"
                        fullWidth
                        value={usersToRemove}
                        onChange={(e) => setUsersToRemove(e.target.value.split(',').map((user) => user.trim()))}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <label htmlFor="import-excel">
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: '10px' }}
                          component="span"
                        >
                          IMPORTAR DE XLS/CSV
                        </Button>
                      </label>


                    </>
                  )}
                </Grid>
              )}

              {actions.promoteAdmins && (
                <Grid item xs={12}>
                  {actions.importContacts ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={promoteAdminsList}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{
                          shrink: true,
                          style: { color: theme.palette.primary.mainReverseByWhite },
                        }}
                          InputProps={{
                            style: {
                              color: theme.palette.primary.mainReverseByWhite,
                            },
                            classes: {
                              notchedOutline: {
                                borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                              },
                            },
                          }} label="Selecionar Admins para Promover" variant="outlined" />
                      )}
                      value={promoteAdminsList.filter((user) => usersToPromote.includes(user.value))}
                      onChange={(event, newValue) => {
                        setUsersToPromote(newValue.map((user) => user.value));
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const user = promoteAdminsList.find((user) => user.value === option.value);
                          return (
                            <Chip
                              key={option.value}
                              label={user ? user.label : 'Nome não disponível'}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  ) : (
                    <>
                      <TextField
                        label="Promover Admins (separado por vírgula)"
                        variant="outlined"
                        fullWidth
                        value={usersToPromote}
                        onChange={(e) => setUsersToPromote(e.target.value.split(',').map((user) => user.trim()))}
                      />

                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <label htmlFor="import-excel">
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: '10px' }}
                          component="span"
                        >
                          IMPORTAR DE XLS/CSV
                        </Button>
                      </label>


                    </>
                  )}
                </Grid>
              )}

              {actions.demoteAdmins && (
                <Grid item xs={12}>
                  {actions.importContacts ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={demoteAdminsList}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField {...params} InputLabelProps={{
                          shrink: true,
                          style: { color: theme.palette.primary.mainReverseByWhite },
                        }}
                          InputProps={{
                            style: {
                              color: theme.palette.primary.mainReverseByWhite,
                            },
                            classes: {
                              notchedOutline: {
                                borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                              },
                            },
                          }} label="Selecionar Admins para remover privilégios de admin" variant="outlined" />
                      )}
                      value={demoteAdminsList.filter((user) => usersToDemote.includes(user.value))}
                      onChange={(event, newValue) => {
                        setUsersToDemote(newValue.map((user) => user.value));
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const user = demoteAdminsList.find((user) => user.value === option.value);
                          return (
                            <Chip
                              key={option.value}
                              label={user ? user.label : 'Nome não disponível'}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  ) : (
                    <>
                      <TextField
                        label="Remover privilégios Admin (separado por vírgula)"
                        variant="outlined"
                        fullWidth
                        value={usersToDemote}
                        onChange={(e) => setUsersToDemote(e.target.value.split(',').map((user) => user.trim()))}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <input
                        accept=".xlsx, .xls, .csv"
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                      />
                      <label htmlFor="import-excel">
                        <Button
                          variant="contained"
                          fullWidth
                          style={{ marginTop: '10px', background: "transparent", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, color: theme.palette.primary.mainReverseByWhite }}
                          component="span"
                        >
                          IMPORTAR DE XLS/CSV
                        </Button>
                      </label>

                    </>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '40px' }}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleExecute}
                  disabled={
                    isSaving ||
                    !selectedGroup ||
                    (!usersToAdd && !usersToPromote.length && !usersToDemote.length && !usersToRemove.length)
                  }
                >
                  {isSaving ? <CircularProgress size={24} /> : 'Executar'}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" fullWidth color="default" onClick={handleClear}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManipulateUsersComponent;
